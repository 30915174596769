import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/SD_Card_Access/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "SD Card Access - SimpleWget",
  "path": "/Motion_Detection/SD_Card_Access/SimpleWget/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Access the Camera Recordings on the internal SD Card",
  "image": "./MD_SearchThumb_SDCardAccess_SimpleWget.png",
  "social": "/images/Search/MD_SearchThumb_SDCardAccess_SimpleWget.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-SD-Card_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='SD Card Access - SimpleWget' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Access the Camera Recordings on the internal SD Card' image='/images/Search/MD_SearchThumb_SDCardAccess_SimpleWget.png' twitter='/images/Search/MD_SearchThumb_SDCardAccess_SimpleWget.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/SD_Karten_Zugriff/SimpleWget/' locationFR='/fr/Motion_Detection/SD_Card_Access/SimpleWget/' crumbLabel="SimpleWget" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "simplewget---ftp-access-to-the-internal-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#simplewget---ftp-access-to-the-internal-sd-card",
        "aria-label": "simplewget   ftp access to the internal sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SimpleWget - FTP Access to the internal SD Card`}</h2>
    <p>{`SimpleWget for macOSX is a wget based client that you can use to access your cameras SD card. We recommend you to download the program from `}<a href="/dl/SimpleWget-Leopard-0.9.dmg">{`here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "491px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a600b656acf9fddcfed9c4e29f20d788/13566/SimpleWget_Step1_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5klEQVQoz0WSS2/TQBRG/f8XrEBs2bBAqCAVoYLSNnITx7HHz/Fz7PHESdykkaoI8QcO8iDE4kr3zuN895u5zmGzopR3rNQW0w3sh5ZB9wxaY8xAISVJHBMGAWVZYoaBvu8ZBk2n1P/oFFr3OFXToDqNalq0Gfnkn/DzHj9tWEcVQd7zFBa4gWQT13ZdFJp1VFPqZ2pzpjYnpH4mykqcuq6RUpImCY3SfPYOhLLHjyvWoiScgUGOG+Rs0wY/qYlLbcHN7kxjTqj9hXa8kMoKZ4bNVoQQ/Lz7Qei59J2yNrMsI4pjG0VRkOcZQkTUTcP1euV4PDKOO6QsrGXVtn+B/8JekoXNg+2Wx+WSKIqQWcpyueTh4R7P80iikDgSVsh1XTxvY8/leY4zdzfH3M3ifoHrPtmN+eJisSAMQ1w/sfkMjUTIo5+xCSKb39x85fbbd1Zrj6IsccZxZL/fM00Tu92OuTbGWIEZ1mnD7Xa0VluluJwnvohXgsLQVgXCXxFtXHSdcTyMODNkGAaECMmy1IKm45GXl4sFp2lKlsSoVlnBvtdcThM7YzifnvmwufLW/c2bh1/EzQFHqc4+poiE/ekZMAvMnSrV2jqOE6qqsvOnlGIwhqZp2ZmBj08T7+/PvFuc7Dj9AU9dbgh5dxLpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a600b656acf9fddcfed9c4e29f20d788/e4706/SimpleWget_Step1_EN.avif 230w", "/en/static/a600b656acf9fddcfed9c4e29f20d788/d1af7/SimpleWget_Step1_EN.avif 460w", "/en/static/a600b656acf9fddcfed9c4e29f20d788/8fad4/SimpleWget_Step1_EN.avif 491w"],
              "sizes": "(max-width: 491px) 100vw, 491px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a600b656acf9fddcfed9c4e29f20d788/a0b58/SimpleWget_Step1_EN.webp 230w", "/en/static/a600b656acf9fddcfed9c4e29f20d788/bc10c/SimpleWget_Step1_EN.webp 460w", "/en/static/a600b656acf9fddcfed9c4e29f20d788/881c7/SimpleWget_Step1_EN.webp 491w"],
              "sizes": "(max-width: 491px) 100vw, 491px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a600b656acf9fddcfed9c4e29f20d788/81c8e/SimpleWget_Step1_EN.png 230w", "/en/static/a600b656acf9fddcfed9c4e29f20d788/08a84/SimpleWget_Step1_EN.png 460w", "/en/static/a600b656acf9fddcfed9c4e29f20d788/13566/SimpleWget_Step1_EN.png 491w"],
              "sizes": "(max-width: 491px) 100vw, 491px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a600b656acf9fddcfed9c4e29f20d788/13566/SimpleWget_Step1_EN.png",
              "alt": "SimpleWget - FTP Access to the internal SD Card",
              "title": "SimpleWget - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`To access your camera, you have to type in its IP address together with your password and username. E.g. if your camera´s IP is 192.168.1.17, your user name is admin and password is 123456 the correct path would be http://admin:`}<a parentName="p" {...{
        "href": "mailto:123456@192.168.1.17"
      }}>{`123456@192.168.1.17`}</a>{`/sd/. Choose a local download directory and set the depth level to 3. By setting the option to Only .avi you will only download the recorded video files.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "491px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cae6921e41c599504be045bf80054059/13566/SimpleWget_Step2_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6UlEQVQoz0WSTY/TMBCG8/8PnEBcuXBAaEGA0IK62yrbpmkS58v5dOK4aZtttxKsEH/gQTFCHF5pxvY874xt57hdU8pb1mqH6QYOQ8ugewatMWagkJIkjgmDgLIsMcNA3/cMg6ZT6r86hdY9TtU0qE6jmhZtRt75Z/y8x08bNqIiyHsewgI3kGzj2q5HhWYjakr9SG0u1OaM1I+IrMSp6xopJWmS0CjNe+9IKHv8uGITlYQzMMhxg5xd2uAnNXGpLbjZX2jMGXW40o5XUlnhzLB5lCiK+H77jdBz6Ttlx8yyDBHHVkVRkOcZUSSom4bn52dOpxPjuEfKwo6s2vYv8J9skSxsHOx2LFcrhBDILGW1WnF/f4fneSQiJBbCGrmui+dt7bk8z3Hm7mbN3SzuFrjug92YCxeLBWEY4vqJjVfLJSIKWfoZ20DY+ObmI5+/fGW98SjKEmccRw6HA9M0sd/vmXNjjDWYYZ02fNqNREJQ1TXTYeSD+EFQGNqqIPLXiK2LrjNOxxFnhgzDQBSFZFlqQdPpxNPT1YLTNCVLYtpWWcNea57OE3tjuJwfebP9yUv3Ny/ufxE3R5yu6+xlChHZl54Bs8HcqVKtzeM4oaoq+/+UUgzG0DQtezPw9mHi9d2FV4szotD8AT0dbfIJzEV5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cae6921e41c599504be045bf80054059/e4706/SimpleWget_Step2_EN.avif 230w", "/en/static/cae6921e41c599504be045bf80054059/d1af7/SimpleWget_Step2_EN.avif 460w", "/en/static/cae6921e41c599504be045bf80054059/8fad4/SimpleWget_Step2_EN.avif 491w"],
              "sizes": "(max-width: 491px) 100vw, 491px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cae6921e41c599504be045bf80054059/a0b58/SimpleWget_Step2_EN.webp 230w", "/en/static/cae6921e41c599504be045bf80054059/bc10c/SimpleWget_Step2_EN.webp 460w", "/en/static/cae6921e41c599504be045bf80054059/881c7/SimpleWget_Step2_EN.webp 491w"],
              "sizes": "(max-width: 491px) 100vw, 491px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cae6921e41c599504be045bf80054059/81c8e/SimpleWget_Step2_EN.png 230w", "/en/static/cae6921e41c599504be045bf80054059/08a84/SimpleWget_Step2_EN.png 460w", "/en/static/cae6921e41c599504be045bf80054059/13566/SimpleWget_Step2_EN.png 491w"],
              "sizes": "(max-width: 491px) 100vw, 491px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cae6921e41c599504be045bf80054059/13566/SimpleWget_Step2_EN.png",
              "alt": "SimpleWget - FTP Access to the internal SD Card",
              "title": "SimpleWget - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`To access your camera, you have to type in its IP address together with your password and username. E.g. if your camera´s IP is 192.168.1.17, your user name is admin and password is 123456 the correct path would be http://admin:`}<a parentName="p" {...{
        "href": "mailto:123456@192.168.1.17"
      }}>{`123456@192.168.1.17`}</a>{`/sd/. Choose a local download directory and set the depth level to 3. By setting the option to Only .jpg you will only download the recorded snapshots.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      